import { useFormik } from 'formik';
import { ChakraProvider, Box, Button, Link, VStack, Grid, theme, Input, FormControl, FormLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { ColorModeSwitcher } from './ColorModeSwitcher';
import { Logo } from './Logo';

import axios from 'axios';
import React from 'react';

export const App = () => {
  const [result, setResult] = React.useState<any>(null);

  const [visible, setVisible] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      code: '',
    },
    onSubmit: (values) => {
      const data = JSON.stringify({
        sqlCommand: 'p_CERTIFICATIONS_Search',
        parameters: {
          code: values.code,
        },
      });
      const config = {
        method: 'post',
        url: 'https://softech.cloud/api/v1.0/dynamic/query/first',
        headers: {
          Authorization: 'Basic 12C1F7EF9AC8E288FBC2177B7F54D',
          ApplicationName: 'Office',
          'Content-Type': 'application/json',
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(response.data?.result);
          if (response.data?.result) {
            setResult(response.data?.result);
            setVisible(true);
          }
        })
        .catch(function (error) {
          setVisible(false);
          console.log(error);
        });
    },
  });
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign='center' fontSize='xl'>
        <Grid minH='100vh' p={3}>
          <ColorModeSwitcher justifySelf='flex-end' />
          <VStack spacing={8}>
            <Logo h='20vmin' pointerEvents='none' />
            <form onSubmit={formik.handleSubmit}>
              <VStack spacing={4} align='flex-start'>
                <FormControl>
                  <FormLabel htmlFor='email'>Certification's number</FormLabel>
                  <Input placeholder='AR-0707222/SOFTECH' id='code' name='code' type='text' onChange={formik.handleChange} value={formik.values.code} />
                </FormControl>

                <Button type='submit' colorScheme='teal' width='full'>
                  Search
                </Button>
              </VStack>
            </form>

            <Modal
              isOpen={visible}
              onClose={() => {
                setVisible(false);
              }}
              closeOnEsc
              isCentered
              colorScheme='teal'
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Certification</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <strong>Full name:</strong>
                      <strong> {result?.title.toUpperCase()}</strong>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <strong>Number:</strong>
                      <span> {result?.subTitle}</span>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <strong>Course:</strong>
                      <span> {result?.tags}</span>
                    </div>
                  </div>
                </ModalBody>

                <ModalFooter>
                  <Button
                    colorScheme='teal'
                    mr={3}
                    onClick={() => {
                      setVisible(false);
                    }}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
            <Link color='teal' href='https://aptech-danang.edu.vn' fontSize='small' target='_blank' rel='noopener noreferrer'>
              Aptech Education | Softech Corporation
            </Link>
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
};
